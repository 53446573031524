import React, { useEffect, useState } from 'react'
import './style.css'
import {
	Route,
	// Link
} from "react-router-dom";
import ImportData from '../ImportData';
import NormalButton from '../../components/commons/NormalButton';

function Home({ match }) {

	const [logo, setLogo] = useState("/assets/logo.png");
	useEffect(()=>{
		let storeLogo = localStorage.getItem('storeLogo');
		if(!!storeLogo) setLogo(storeLogo);
	},[])

	return (
		<div className='Home-container'>
			<div className='Home-sidebar'>
				<a href='https://tictag.com.br/' title='Voltar para o TicTag'>
					<img className='Home-sidebar-logo' src={logo} alt='logo' />
				</a>
				<NormalButton 
					label='Importação de Cadastros'
					onClick={()=>window.location.href='/home/import-data/'}
				/>
			</div>

			<div className='Home-main-container'>
				{/* <Route path={`${match.path}/`} exact component={Home}/> */}
				<Route path={`${match.path}/import-data`} component={ImportData} />
			</div>
		</div>
	)
}

export default Home