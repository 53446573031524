import React from 'react'
import './style.css'

function ImportMarcaInfoStep2() {
  return (
    <div className='ImportMarcaInfoStep2'>
        <p>Você está na primeira etapa do assistente de importação. </p>

        <p>Vamos te orientar no Importação das <b>MARCAS</b>.</p>

        <p><b>Atenção:</b> Caracteres estranhos nas descrição da marca serão removidos.</p>

        <p>Nesta tela você deve indicar o arquivo .CSV que contem as categorias relacionadas aos seus produtos.</p>
    </div>
  )
}

export default ImportMarcaInfoStep2;