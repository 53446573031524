import './App.css';
import {
	BrowserRouter as Router,
	Route,
	// Link
} from "react-router-dom";
import { useSelector } from 'react-redux';
import Home from './pages/Home';
import SetUpToken from './components/SetUpAuth';
import Landing from './pages/Landing';
import GlobalLoader from './components/commons/GlobalLoader';

function App() {
	const { isLoanding } = useSelector((state) => state.navbar);
	return (
		<>
			{!!isLoanding && <GlobalLoader />}
			<Router>
				<Route path='/' exact component={Landing}/>
				<Route path='/token' component={SetUpToken}/>
				<Route path='/home' component={Home}/>
			</Router>
		</>
	);
}

export default App;
