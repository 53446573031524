import React from 'react'
import {
	// BrowserRouter as Router,
	Route,
	// Link
} from "react-router-dom";
import ImportDataCategory from '../../components/ImportDataCategory';
import ImportDataClients from '../../components/ImportDataClients';
import ImportDataFornecedores from '../../components/ImportDataFornecedores';
import ImportDataMarca from '../../components/ImportDataMarca';
import ImportDataProdutos from '../../components/ImportDataProdutos';
import ImportDataTamanhos from '../../components/ImportDataTamanhos';
import ImportInfo from '../../components/ImportInfo';

function ImportData({ match }) {
  return (
    <>
      <Route path={`${match.path}`} exact component={ImportInfo}/>
      <Route path={`${match.path}/CATEGORIAS/`} component={ImportDataCategory}/>
      <Route path={`${match.path}/MARCAS/`} component={ImportDataMarca}/>
      <Route path={`${match.path}/TAMANHOS/`} component={ImportDataTamanhos}/>
      <Route path={`${match.path}/FORNECEDORES/`} component={ImportDataFornecedores}/>
      <Route path={`${match.path}/CLIENTES/`} component={ImportDataClients}/>
      <Route path={`${match.path}/PRODUTOS/`} component={ImportDataProdutos}/>
    </>
  )
}

export default ImportData