import React from 'react'
import './style.css'

function ImportCategoryInfoStep2() {
  return (
    <div className='ImportCategoryInfoStep2'>
        <p>Você está na primeira etapa do assistente de importação. </p>

        <p>Vamos te orientar no Importação das <b>CATEGORIAS</b>.</p>
        
        <p><b>Atenção:</b> Caracteres estranhos nas descrição da categoria serão removidos.</p>

        <p>Nesta tela você deve indicar o arquivo .CSV que contem as categorias relacionadas aos seus produtos.</p>
    </div>
  )
}

export default ImportCategoryInfoStep2;