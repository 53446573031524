import React, { useState } from 'react'
import NormalButton from '../commons/NormalButton';
import './style.css'
import {pad_with_zeroes} from '../../actions/commons'

function ImportInfo() {

	const [showInfo, setShowInfo] = useState(false);
	let storeName = localStorage.getItem("storeName");
	let storeId = localStorage.getItem("storeId"); 
	let username = localStorage.getItem("username"); 

	return (
		<div className='ImportInfo'>

			<div className="ImportInfo-header">
                <h3>Importação de Cadastros</h3>
            </div>

			{(!!storeName || !!storeId || !!username) && <>
				<div className="ImportInfo-header-details">
					{!!storeId && <><h3>LOJA: {pad_with_zeroes(storeId)}</h3></>}
					{!!storeName && <><h3>NOME: {storeName}</h3></>}
					{!!username && <><h3>USUÁRIO: {username}</h3></>}
				</div>
			</>}

			<p>Você está na página do assistente de importação. Vamos te orientar <br/> no processo de importação das informações para o sistema.</p>

			<p>Cada tela vai solicitar que você indique um arquivo com extensão <br/> .CSV contendo as informações a serem importadas.</p>

			<p>Comece importando os cadastros abaixo, para isso, para cada um dos cadastros você vai clicar no botão correspondente:</p>
			<div className='ImportInfo-buttons'>
				<NormalButton label='CATEGORIAS' onClick={()=>{window.location.href='/home/import-data/CATEGORIAS/'}} />
				<NormalButton label='MARCAS' onClick={()=>{window.location.href='/home/import-data/MARCAS/'}} />
				<NormalButton label='TAMANHOS' onClick={()=>{window.location.href='/home/import-data/TAMANHOS/'}} />
				<NormalButton label='FORNECEDORES' onClick={()=>{window.location.href='/home/import-data/FORNECEDORES/'}} />
				<NormalButton label='CLIENTES' onClick={()=>{window.location.href='/home/import-data/CLIENTES/'}} />
			</div>
			<p>Em seguida importe o cadastro de produtos:</p>
			<div className='ImportInfo-buttons'>
				<NormalButton label='PRODUTOS' classNames='ImportInfo-button-products' onClick={()=>setShowInfo(true)} />
			</div>

			{showInfo && <>

				<p>Você clicou para importar PRODUTOS, sem antes ter importado os cadastros acima. <br/> 
				Você pode fazer isso desde que os cadastros acima já estejam feitos no sistema. <br/> 
				Se algum campo estiver faltando, o que vai acontecer é que você verá mensagens de erro de importação <br/>
				explicando o motivo pelo qual o produto não foi importado, tais como:.</p>
				<ul className='ImportInfo-listItens'>
					<li>"Produto não importado porque o fornecedor indicado não existe no sistema."</li>
					<li>"Produto não importado porque a categoria, marca ou tamanho não existe no sistema."</li>
					<li>"Produto não importado porque a comissão contém caracteres estranhos, como, por exemplo o sinal de percentual (%). <br/>
					O campo comissão deve ter apena o valor da comissão, por exemplo 55% = 55"</li>
					<li>"Produto não importado porque a preço contém caracteres estranhos, como, por exemplo o sinal R$. <br/>
					O campo preço deve ter apena o valor do preço, por exemplo R$ 122,00 = 122,00"</li>
					<li>"Caracteres estranhos nas descrição de qualquer item importado serão removidos."</li>
				</ul>

				<div className='ImportInfo-buttons'>
					<NormalButton label='CONTINUAR ASSIM MESMO' classNames='ImportInfo-button-products' onClick={()=>{window.location.href='/home/import-data/PRODUTOS/'}} />
				</div>
			</>}


		</div>
	)
}

export default ImportInfo;