import React from 'react'
import './style.css'

function ImportClientesInfoStep2() {
  return (
    <div className='ImportClientesInfoStep2'>
        <p>Você está na primeira etapa do assistente de importação. </p>

        <p>Vamos te orientar no Importação dos <b>CLIENTES</b>.</p>

        <p><b>Atenção:</b> Caracteres estranhos nas descrição do cliente serão removidos.</p>

        <p>Nesta tela você deve indicar o arquivo .CSV que contem as categorias relacionadas aos seus produtos.</p>
    </div>
  )
}

export default ImportClientesInfoStep2;