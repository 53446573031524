import React, { useState } from "react";
import './style.css'
import Papa from "papaparse";
import NormalButton from "../commons/NormalButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import ImportClientesInfoStep2 from "../shortDescs/ImportClientesInfoStep2";
import ImportColumnSelector from "../ImportColumnSelector";
import { addClientsByByCsvDataApi } from "../../apis/importApis";
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/navbar';
import {pad_with_zeroes, removeSpecialCharacters} from '../../actions/commons'
 
// Allowed extensions for input file
const allowedExtensions = ["csv"];
 
let initfailedData = []
let initSuccessCount = 0;
let initFailedCount = 0;


const ImportDataClients = ({ history }) => {

    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [file, setFile] = useState("");
    const [step, setStep] = useState(1);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [fileData, setFileData] = useState(null);

    const [idFieldName, setIdFieldName] = useState('');
    const [nomeFieldName, setNomeFieldName] = useState('');
    const [email1FieldName, setEmail1FieldName] = useState('');
    const [cpfFieldName, setCpfFieldName] = useState('');
    const [fone1FieldName, setFone1FieldName] = useState('');
    const [ruaFieldName, setRuaFieldName] = useState('');
    const [numeroFieldName, setNumeroFieldName] = useState('');
    const [bairroFieldName, setBairroFieldName] = useState('');
    const [cepFieldName, setCepFieldName] = useState('');

    const [dataWithProblem, setDataWithProblem] = useState([]);
    const [successCount, setSuccessCount] = useState(0);
    const [failureCount, setFailureCount] = useState(0);

    let storeName = localStorage.getItem("storeName");
	let storeId = localStorage.getItem("storeId"); 
	let username = localStorage.getItem("username"); 

 
    const handleFileChange = (e) => {
        setError("");
         
        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
             
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Por favor escolha uma aquivo com extensão .csv");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }
    };
    const handleParse = () => {
         
        // If user clicks the parse button without
        // a file we show a error
        if (!file) return setError("Escolha um arquivo .csv válido.");
 
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            if(parsedData.length<2) setError("Por favor selecione um arquivo que tenha pelo menos um item.");
            else{
                const columns = Object.keys(parsedData[0]);
                if(columns.length<2) setError("Por favor selecione um arquivo que tenha pelo menos duas colunas.");
                else{
                    setFileData(parsedData);
                    setFileHeaders(columns);
                    setStep(2);
                }
            }
        };
        reader.readAsText(file);
    };

    const callingUploadFunc = async (batches) => {
        await addClientsByByCsvDataApi(batches.shift()).then(res=>{
            if(res.data.status==="success"){

                initfailedData = [...initfailedData,...res.data.dataWithProblem];
                initSuccessCount += res.data.successCount;
                initFailedCount += res.data.failureCount;

                if(batches.length === 0){
                    setDataWithProblem(initfailedData);
                    setSuccessCount(initSuccessCount);
                    setFailureCount(initFailedCount);
                    setStep(3);
                }
            }else{
                setError(res.data.message);
            }
        }).catch(err=>console.log(err));
        
        if(batches.length !== 0) {
            await callingUploadFunc(batches);
        }
    }

    const uploadData = async () => {

        if(!idFieldName || !nomeFieldName){
            alert("Selecione os campos necessários");
            return;
        }

        let dataToUpload = [];
        
        fileData.forEach(tempRec=>{
            dataToUpload.push({
                'sp_id' :  tempRec[idFieldName],
                'nome' :  removeSpecialCharacters(tempRec[nomeFieldName]),
                'email1' : (!!email1FieldName && email1FieldName!=="empty") ? tempRec[email1FieldName] : "",
                'cpf' : (!!cpfFieldName && cpfFieldName!=="empty") ? tempRec[cpfFieldName] : "",
                'fone1': (!!fone1FieldName && fone1FieldName !== "empty") ? tempRec[fone1FieldName] : "",
                'rua' : (!!ruaFieldName && ruaFieldName!=="empty") ? tempRec[ruaFieldName] : "",
                'numero' : (!!numeroFieldName && numeroFieldName!=="empty") ? tempRec[numeroFieldName] : "",
                'bairro' : (!!bairroFieldName && bairroFieldName!=="empty") ? tempRec[bairroFieldName] : "",
                'cep' : (!!cepFieldName && cepFieldName!=="empty") ? tempRec[cepFieldName] : "",
            })
        })
        
        dispatch(setIsLoading(true));

        let batches = []
        while(dataToUpload.length !== 0){
            let batch = [];
            while(batch.length < 200 && dataToUpload.length !== 0){
                batch.push(dataToUpload.shift());
            }
            batches.push(batch)
        }

        await callingUploadFunc(batches);

        // await addClientsByByCsvDataApi(dataToUpload).then(res=>{
        //     if(res.data.status==="success"){
        //         setDataWithProblem(res.data.dataWithProblem);
        //         setSuccessCount(res.data.successCount);
        //         setFailureCount(res.data.failureCount);
        //         setStep(3);

        //     }else{
        //         setError(res.data.message);
        //     }
        // }).catch(err=>console.log(err));

        dispatch(setIsLoading(false));

    }

    const changeLevel = () => {
        if(step === 1){
            handleParse();
        }else if(step === 2){
            uploadData();
        }
    }
 
    return (
        <div className="ImportDataClients">

            <div className="ImportDataClients-header">
                <h3>Importação de Clientes</h3>
            </div>

            {(!!storeName || !!storeId || !!username) && <>
				<div className="ImportInfo-header-details">
					{!!storeId && <><h3>LOJA: {pad_with_zeroes(storeId)}</h3></>}
					{!!storeName && <><h3>NOME: {storeName}</h3></>}
					{!!username && <><h3>USUÁRIO: {username}</h3></>}
				</div>
			</>}

            <div className="ImportDataClients-container">

                {step === 1 && <>
                    <ImportClientesInfoStep2 />
                    <div className="ImportDataClients-step2-button-div">
                        <label htmlFor="ImportDataClients-csvInput" id="ImportDataClients-csvInputLabel">
                            <FontAwesomeIcon icon={faFileCsv} />
                        </label>
                        <h5>{!!file && <>{file.name}</>}</h5>
                    </div>
                    <input
                        onChange={handleFileChange}
                        id="ImportDataClients-csvInput"
                        name="file"
                        type="File"
                    />
                </>}

                {step === 2 && <>
                    <h5 className="ImportDataClients-local-text">Agora você deve indicar qual coluna da sua planilha .CSV deve ser importada para cada campo do TicTag.</h5>
                    <div>
                        <ImportColumnSelector 
                            columnLabel="ID*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={idFieldName}
                            onChange={e=>{setIdFieldName(e.target.value)}}
                            exapleLabel={!!idFieldName ? fileData[0][idFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="NOME*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={nomeFieldName}
                            onChange={e=>{setNomeFieldName(e.target.value)}}
                            exapleLabel={!!nomeFieldName ? fileData[0][nomeFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="EMAIL 1"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={email1FieldName}
                            onChange={e=>{setEmail1FieldName(e.target.value)}}
                            exapleLabel={(!!email1FieldName && email1FieldName!=="empty") ? fileData[0][email1FieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="CPF/CNPJ"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={cpfFieldName}
                            onChange={e=>{setCpfFieldName(e.target.value)}}
                            exapleLabel={(!!cpfFieldName && cpfFieldName!=="empty") ? fileData[0][cpfFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="FONE 1"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={fone1FieldName}
                            onChange={e=>{setFone1FieldName(e.target.value)}}
                            exapleLabel={(!!fone1FieldName && fone1FieldName!=="empty") ? fileData[0][fone1FieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="RUA"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={ruaFieldName}
                            onChange={e=>{setRuaFieldName(e.target.value)}}
                            exapleLabel={(!!ruaFieldName && ruaFieldName!=="empty") ? fileData[0][ruaFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="NUMERO"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={numeroFieldName}
                            onChange={e=>{setNumeroFieldName(e.target.value)}}
                            exapleLabel={(!!numeroFieldName && numeroFieldName!=="empty") ? fileData[0][numeroFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="BAIRRO"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={bairroFieldName}
                            onChange={e=>{setBairroFieldName(e.target.value)}}
                            exapleLabel={(!!bairroFieldName && bairroFieldName!=="empty") ? fileData[0][bairroFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            columnLabel="CEP"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={cepFieldName}
                            onChange={e=>{setCepFieldName(e.target.value)}}
                            exapleLabel={(!!cepFieldName && cepFieldName!=="empty") ? fileData[0][cepFieldName] : "---------"}
                        />
                    </div>
                </>}

                {step === 3 && <>
                    <div className="ImportDataClients-result-box">
                        <div className="ImportDataClients-result-box-left">
                            <div className="ImportDataClients-result-box-left-c1">
                                <h5 className="ImportDataClients-result-box-left-c1-t1">Itens importados com sucesso</h5>
                                <h5 className="ImportDataClients-result-box-left-c1-t2">{successCount}</h5>
                            </div>
                            <div className="ImportDataClients-result-box-left-c1">
                                <h5 className="ImportDataClients-result-box-left-c1-t1 text-danger">Itens não importados</h5>
                                <h5 className="ImportDataClients-result-box-left-c1-t2 btn-danger">{failureCount}</h5>
                            </div>
                        </div>
                        <div className="ImportDataClients-result-box-right">
                            <NormalButton label="CARREGAR NOVAMENTE" classNames="ImportDataClients-finish-button" 
                                onClick={() => {
                                    // history.push('/home/import-data/marcas/');
                                    window.location.href = '/home/import-data/CLIENTES/';
                                }} 
                            />
                            <NormalButton label="IMPORTAR PRODUTOS" classNames="ImportDataClients-finish-button" 
                                onClick={() => {
                                    // history.push('/home/import-data/marcas/');
                                    window.location.href = '/home/import-data/PRODUTOS/';
                                }} 
                            />
                        </div>
                    </div>
                    <table className="ImportDataClients-results-table">
                        <thead className="">
                            <tr>
                                <td>ID</td>
                                <td>OBSERVAÇÕES</td>
                            </tr>
                        </thead>
                        <tbody>
                            {dataWithProblem.map((tempSpId, i)=><>
                                <tr key={i}>
                                    <td>{tempSpId[0]}</td>
                                    <td className="text-danger">{tempSpId[1]}</td>
                                </tr>
                            </>)}
                        </tbody>
                    </table>
                </>}

                {!!error && <><h5 className="ImportDataClients-error">{error}</h5></>}
            </div>


            <div className="ImportDataClients-footer">
                {(step===1) && <>
                    <NormalButton label="SKIP" classNames="ImportDataClients-footer-button ImportDataClients-footer-button-warning" 
                        onClick={() => {
                            // history.push('/home/import-data/marcas/');
                            window.location.href = '/home/import-data/PRODUTOS/';
                        }} 
                    />
                </>}
                {(step !== 3) && <>
                    <NormalButton label="PRÓXIMA ETAPA"  classNames="ImportDataClients-footer-button" onClick={changeLevel} />
                </>}
            </div>
            
        </div>
    );
};

export default ImportDataClients;