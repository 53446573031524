import React, { useState } from "react";
import './style.css'
import Papa from "papaparse";
import NormalButton from "../commons/NormalButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import ImportProdutosInfoStep2 from "../shortDescs/ImportProdutosInfoStep2";
import ImportColumnSelector from "../ImportColumnSelector";
import { addProdutosByCsvDataByApi } from "../../apis/importApis";
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/navbar';
import {pad_with_zeroes, removeSpecialCharacters} from '../../actions/commons'
 
// Allowed extensions for input file
const allowedExtensions = ["csv"];

let initfailedData = []
let initSuccessCount = 0;
let initFailedCount = 0;

 
const ImportDataProdutos = ({ history }) => {

    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [file, setFile] = useState("");
    const [step, setStep] = useState(1);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [fileData, setFileData] = useState(null);

    const [idFieldName, setIdFieldName] = useState('');
    const [descFieldName, setDescFieldName] = useState('');
    const [fornecedorFieldName, setFornecedorFieldName] = useState('');
    const [marcaFieldName, setMarcaFieldName] = useState('');
    const [categoryFieldName, setCategoryFieldName] = useState('');
    const [tamanhoFieldName, setTamanhoFieldName] = useState('');
    const [altIdFieldName, setAltIdFieldName] = useState('');
    const [comissionFieldName, setComissionFieldName] = useState('');
    const [prceFieldName, setPriceFieldName] = useState('');
    const [costFieldName, setCostFieldName] = useState('');

    const [dataWithProblem, setDataWithProblem] = useState([]);
    const [successCount, setSuccessCount] = useState(0);
    const [failureCount, setFailureCount] = useState(0);

    let storeName = localStorage.getItem("storeName");
	let storeId = localStorage.getItem("storeId"); 
	let username = localStorage.getItem("username"); 

 
    const handleFileChange = (e) => {
        setError("");
         
        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
             
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Por favor escolha uma aquivo com extensão .csv");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }
    };
    const handleParse = () => {
         
        // If user clicks the parse button without
        // a file we show a error
        if (!file) return setError("Escolha um arquivo .csv válido.");
 
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            if(parsedData.length<2) setError("Por favor selecione um arquivo que tenha pelo menos um item.");
            else{
                const columns = Object.keys(parsedData[0]);
                if(columns.length<4) setError("Por favor selecione um arquivo que tenha pelo menos quatro colunas.");
                else{
                    setFileData(parsedData);
                    setFileHeaders(columns);
                    setStep(2);
                }
            }
        };
        reader.readAsText(file);
    };

    const callingUploadFunc = async (batches) => {
        await addProdutosByCsvDataByApi(batches.shift()).then(res=>{
            if(res.data.status==="success"){

                initfailedData = [...initfailedData,...res.data.dataWithProblem];
                initSuccessCount += res.data.successCount;
                initFailedCount += res.data.failureCount;

                if(batches.length === 0){
                    setDataWithProblem(initfailedData);
                    setSuccessCount(initSuccessCount);
                    setFailureCount(initFailedCount);
                    setStep(3);
                }
            }else{
                setError(res.data.message);
            }
        }).catch(err=>console.log(err));
        
        if(batches.length !== 0) {
            await callingUploadFunc(batches);
        }
    }

    const uploadData = async () => {

        if(!idFieldName || !descFieldName || !fornecedorFieldName || !marcaFieldName || !categoryFieldName || !tamanhoFieldName){
            alert("Selecione os campos necessários");
            return;
        }

        let dataToUpload = [];
        
        fileData.forEach(tempRec=>{
            dataToUpload.push({
                'sp_id' :  tempRec[idFieldName],
                'description' :  removeSpecialCharacters(tempRec[descFieldName]),
                'fornecedor' : (!!fornecedorFieldName && fornecedorFieldName!=="empty") ? tempRec[fornecedorFieldName] : "",
                'marca' : (!!marcaFieldName && marcaFieldName!=="empty") ? tempRec[marcaFieldName] : "",
                'category' : (!!categoryFieldName && categoryFieldName!=="empty") ? tempRec[categoryFieldName] : "",
                'tamanho' : (!!tamanhoFieldName && tamanhoFieldName!=="empty") ? tempRec[tamanhoFieldName] : "",
                'altId' : (!!altIdFieldName && altIdFieldName!=="empty") ? tempRec[altIdFieldName] : "",
                'comission' : (!!comissionFieldName && comissionFieldName!=="empty") ? tempRec[comissionFieldName] : "",
                'price' : (!!prceFieldName && prceFieldName!=="empty") ? tempRec[prceFieldName] : "",
                'cost' : (!!costFieldName && costFieldName!=="empty") ? tempRec[costFieldName] : "",
            })
        })
        
        dispatch(setIsLoading(true));

        let batches = []
        while(dataToUpload.length !== 0){
            let batch = [];
            while(batch.length < 200 && dataToUpload.length !== 0){
                batch.push(dataToUpload.shift());

            }
            batches.push(batch)
        }

        await callingUploadFunc(batches);

        dispatch(setIsLoading(false));

    }

    const changeLevel = () => {
        if(step === 1){
            handleParse();
        }else if(step === 2){
            uploadData();
        }
    }
 
    return (
        <div className="ImportDataProdutos">

            <div className="ImportDataProdutos-header">
                <h3>Importação de Produtos</h3>
            </div>

            {(!!storeName || !!storeId || !!username) && <>
				<div className="ImportInfo-header-details">
					{!!storeId && <><h3>LOJA: {pad_with_zeroes(storeId)}</h3></>}
					{!!storeName && <><h3>NOME: {storeName}</h3></>}
					{!!username && <><h3>USUÁRIO: {username}</h3></>}
				</div>
			</>}

            <div className="ImportDataProdutos-container">

                {step === 1 && <>
                    <ImportProdutosInfoStep2 />
                    <div className="ImportDataProdutos-step2-button-div">
                        <label htmlFor="ImportDataProdutos-csvInput" id="ImportDataProdutos-csvInputLabel">
                            <FontAwesomeIcon icon={faFileCsv} />
                        </label>
                        <h5>{!!file && <>{file.name}</>}</h5>
                    </div>
                    <input
                        onChange={handleFileChange}
                        id="ImportDataProdutos-csvInput"
                        name="file"
                        type="File"
                    />
                </>}

                {step === 2 && <>
                    <h5 className="ImportDataProdutos-local-text">Agora você deve indicar qual coluna da sua planilha .CSV deve ser importada para cada campo do TicTag.</h5>
                    <div>
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="ID*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={idFieldName}
                            onChange={e=>{setIdFieldName(e.target.value)}}
                            exapleLabel={!!idFieldName ? fileData[0][idFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="DESCRIÇÃO*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={descFieldName}
                            onChange={e=>{setDescFieldName(e.target.value)}}
                            exapleLabel={!!descFieldName ? fileData[0][descFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="FORNECEDOR*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={fornecedorFieldName}
                            onChange={e=>{setFornecedorFieldName(e.target.value)}}
                            exapleLabel={(!!fornecedorFieldName && fornecedorFieldName!=="empty") ? fileData[0][fornecedorFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="MARCA*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={marcaFieldName}
                            onChange={e=>{setMarcaFieldName(e.target.value)}}
                            exapleLabel={(!!marcaFieldName && marcaFieldName!=="empty") ? fileData[0][marcaFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="CATEGORIA*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={categoryFieldName}
                            onChange={e=>{setCategoryFieldName(e.target.value)}}
                            exapleLabel={(!!categoryFieldName && categoryFieldName!=="empty") ? fileData[0][categoryFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="TAMANHO*"
                            selectOptions={fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})}
                            selectedValue={tamanhoFieldName}
                            onChange={e=>{setTamanhoFieldName(e.target.value)}}
                            exapleLabel={(!!tamanhoFieldName && tamanhoFieldName!=="empty") ? fileData[0][tamanhoFieldName] : "---------"}
                        />
                        {/* OPTIONAL FIELDS */}

                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="ALT ID"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={altIdFieldName}
                            onChange={e=>{setAltIdFieldName(e.target.value)}}
                            exapleLabel={(!!altIdFieldName && altIdFieldName!=="empty") ? fileData[0][altIdFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="COMISSAO"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={comissionFieldName}
                            onChange={e=>{setComissionFieldName(e.target.value)}}
                            exapleLabel={(!!comissionFieldName && comissionFieldName!=="empty") ? fileData[0][comissionFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="PRECO"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={prceFieldName}
                            onChange={e=>{setPriceFieldName(e.target.value)}}
                            exapleLabel={(!!prceFieldName && prceFieldName!=="empty") ? fileData[0][prceFieldName] : "---------"}
                        />
                        <ImportColumnSelector 
                            labelminWidth="130PX"
                            columnLabel="CUSTO"
                            selectOptions={[{label:"-----VAZIO-----",value:"empty"}, ...fileHeaders.filter(col=>!!col).map(col=>{return{label:col,value:col}})]}
                            selectedValue={costFieldName}
                            onChange={e=>{setCostFieldName(e.target.value)}}
                            exapleLabel={(!!costFieldName && costFieldName!=="empty") ? fileData[0][costFieldName] : "---------"}
                        />
                    </div>
                </>}

                {step === 3 && <>
                    <div className="ImportDataProdutos-result-box">
                        <div className="ImportDataProdutos-result-box-left">
                            <div className="ImportDataProdutos-result-box-left-c1">
                                <h5 className="ImportDataProdutos-result-box-left-c1-t1">Itens importados com sucesso</h5>
                                <h5 className="ImportDataProdutos-result-box-left-c1-t2">{successCount}</h5>
                            </div>
                            <div className="ImportDataProdutos-result-box-left-c1">
                                <h5 className="ImportDataProdutos-result-box-left-c1-t1 text-danger">Itens não importados</h5>
                                <h5 className="ImportDataProdutos-result-box-left-c1-t2 btn-danger">{failureCount}</h5>
                            </div>
                        </div>
                        <div className="ImportDataProdutos-result-box-right">
                            <NormalButton label="CARREGAR NOVAMENTE" classNames="ImportDataProdutos-finish-button" 
                                onClick={() => {
                                    // history.push('/home/import-data/marcas/');
                                    window.location.href = '/home/import-data/PRODUTOS/';
                                }} 
                            />
                            <NormalButton label="Importação de Cadastros" classNames="ImportDataProdutos-finish-button" 
                                onClick={() => {
                                    // history.push('/home/import-data/marcas/');
                                    window.location.href = '/home/import-data/';
                                }} 
                            />
                        </div>
                    </div>
                    <table className="ImportDataProdutos-results-table">
                        <thead className="">
                            <tr>
                                <td>ID</td>
                                <td>OBSERVAÇÕES</td>
                            </tr>
                        </thead>
                        <tbody>
                            {dataWithProblem.map((tempSpId, i)=>{
                            // console.log(i)
                            return <>
                                <tr key={i}>
                                    <td>{tempSpId[0]}</td>
                                    <td className="text-danger">{tempSpId[1]}</td>
                                </tr>
                            </>})}
                        </tbody>
                    </table>
                </>}

                {!!error && <><h5 className="ImportDataProdutos-error">{error}</h5></>}
            </div>


            <div className="ImportDataProdutos-footer">
                {(step===1) && <>
                    <NormalButton label="SKIP" classNames="ImportDataProdutos-footer-button ImportDataProdutos-footer-button-warning" 
                        onClick={() => {
                            // history.push('/home/import-data/marcas/');
                            window.location.href = '/home/import-data/';
                        }} 
                    />
                </>}
                {(step !== 3) && <>
                    <NormalButton label="PRÓXIMA ETAPA"  classNames="ImportDataProdutos-footer-button" onClick={changeLevel} />
                </>}
            </div>
            
        </div>
    );
};

export default ImportDataProdutos;