import React from 'react'
import './style.css'

function NormalButton({
    label = "submit",
    classNames = "",
    onClick
}) {
  return (
    <div 
        className={'NormalButton ' + classNames}
        onClick={onClick}
    >{label}</div>
  )
}

export default NormalButton