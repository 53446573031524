import React, { useEffect, useState } from 'react'
import { getUserProfileDataApi } from '../../apis/importApis';
import './style.css';

function SetUpToken() {

    const [message, setMessage] = useState('Authenticating....')

    const getAndSetData = async (token) => {
        await getUserProfileDataApi(token).then(res=>{
            if(res.data.status==='success'){
                localStorage.setItem("username", res.data.username);
                localStorage.setItem("storeName", res.data.storeName);
                localStorage.setItem("storeId", res.data.storeId);
                localStorage.setItem("storeLogo", res.data.storeLogo);
                window.location.href = '/home/import-data/';
            }else{
                setMessage("Authentication Failed!")
            }
        }).catch(err=>{console.log(err);setMessage("Authentication Failed!");});
    }

    useEffect(()=>{

        let tokenList = window.location.href.split("token/");
        if(tokenList.length>1){
            let token = tokenList[1].split("/")[0];
            localStorage.setItem('token', token);
            getAndSetData(token);
        }

    },[])

    return (<div className='SetUpToken'>
                <div className="stars">
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                </div>
                <div className='SetUpToken-head'>
                    <h4>{message}</h4>
                </div>
            </div>
    )
}

export default SetUpToken;